<template>
  <div>
    <Header/> 
    <div class="content_text_a" @click="redeemCode">
      <!-- <router-link :to="{path:'/'}">
        <img class="content_img_1_a" src="../../assets/content/logo_s.png">
      </router-link> -->
        <img class="content_img_2_a" :src="logo">
        <div calss="content_study_a">{{logo_title}}</div>
    </div>
    
    <div>
        <div v-if="status">
            <div class="viplist-title xet-pay-con-title">您正在查看的課程：</div>
            <div class="topic-item">
                <img src="../../assets/vippay/1.jpg" style="width:100%;height:100%;">
                <div class="vip-icon">
                  <img src="../../assets/content/radio_selected.png">
                </div>
            </div>
        </div>
        <div class="svs_div_one">
            <button class="clear-btn_1s round-btn_1s button_details" @click="redeemCodes">
                <img src="../../assets/vippay/logo.png">使用兌換碼
            </button>
        </div>
        <div class="viplist-title xet-pay-con-title">{{text}}</div>

        <div class="grace-btdialog-shade" v-if="redeemCode_dialog" @click.stop="redeemCode">
            <div class="dialog gdIn" @click.stop="redeemCode">
                <div style="padding: 20px;">
                    <p style="color: rgb(102, 102, 102);text-align: center;font-weight: bold;margin-bottom: 10px;">請輸入兌換碼</p>
                    <input type="text" class="dialog_inp" v-model="vippay_code" placeholder="請輸入兌換碼">
                    <button class="grace-buttons" @click="save">提交</button>
                </div>
            </div>
        </div>
      <div style="width:100%;height:90px;"></div>
      <div style="width:100%;height:90px;"></div>
    </div>

    <Footer />
  </div>
</template>

<style>
.grace-buttons{
    background: #C49B6C !important;
    width:100%;
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 42px;
    padding: 0;
    border-radius: 2px;
    margin-top:3vh;
}
.dialog_inp{
    width:100%;
    display: block;
    border: 1px solid #999;
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    height: 36px;
    color: #333;
    border-radius: 10px;
}
p{
    margin:0;
}
.gdIn{
    animation: gdIn-data-v-2f613eb3 .2s linear forwards;
}
.dialog{
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0;
}
.grace-btdialog-shade{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    background: rgba(0,0,0,.5);
    color: #fff;
}
.vip-icon img{
  margin-top: 55px;
  width: 20px;
  height: 20px;
}
.vip-icon{
  position: absolute;
  padding: 0;
  right: 5px;
  top: 0;
  bottom: 0;
  /* background-color: rgba(255, 0, 0, 0.3); */
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-filter: drop-shadow(0 0 4px #d4b661);
  filter: drop-shadow(0 0 4px #d4b661);
}
.topic-item{
    width: 100%;
    height: 100.8px;
    position: relative;
    margin: 0;
    border-bottom: 1px solid #fff;
}
.xet-pay-con-title{
    padding-left: 18px;
    font-family: Helvetica Neue;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    color: #fff;
}
.viplist-title{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 50px /* border-bottom: 1px solid #fff; */;
}
.button_details{
    margin-top:1vh;
    margin-left: auto;
    margin-right: auto;
}
.clear-btn_1s{
    background: #C49B6C !important;
    box-shadow: 0 0 7px #d5d6d8;
    border: none;
}
.round-btn_1s{
    display: flex;
    color: #fff;
    line-height: 130%;
    font-size: 16px;
    padding: 1.5vh 30vw;
    width: 100%;
}
.svs_div_one{
    padding: 15px;
    text-align: center;
}
.content_study_a{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
}
.content_text_a{
    margin-top: 5vh;
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #fff;
}
.content_img_1_a{
    margin-right: 13px;
    width: 60px;
    height: 55px;
}
.content_img_2_a{
    width: 70px;
    height: 70px;
    margin-right: 8px;
}
</style>
<script>
import Header from "../Header";
import {userVipApplications} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
      vippay_code:'',
      status:false,
      logo:require('@/assets/vippay/vip_icon.png'),
      logo_title:"VIP 產品中心",
      text:'所有產品：',
      redeemCode_dialog:false,
    }
  },
  created(){
      let id = this.$route.params.id;

      if(id%2 == 0){
          this.status = true;
          this.text = '包含此課程的產品：';
      }
  },
 watch:{
     
  },
  methods:{
    save(){
        let vippay_code = this.vippay_code;
        if(!vippay_code){
            this.$message.error('請輸入兌換碼');
            return false;
        }else{
            userVipApplications({
                redeem_code:vippay_code
            }).then(res=>{
                if(res.code == 200){
                    this.$message.success('兌換碼已提交，待管理員進行審核！');
                }else{
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                console.log(error);
            })
            this.redeemCode_dialog = false;
        }
    },
    redeemCodes(){
        this.redeemCode_dialog = true;
    },
    redeemCode(val){
        // 点击子元素时 阻止触发父级方法
        let event1 = val.currentTarget;
        let event2 = val.target;
        // 当event1 == event2 则表示点击的是子级以外的所有父级内容 则关闭输入兑换码
        if(event1 == event2){   
            this.redeemCode_dialog = false;
        }
    }
  }
}
</script>

